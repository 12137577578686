<template>
  <div class="new-task-form">
    <card>
      <h3 class="title">Create New Task</h3>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="submitTask">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Task Name">
              <el-input v-model="newTask.name" placeholder="Enter task name"></el-input>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <fg-input label="Details">
            <vue-editor ref="VueEditor" class="quill-additional-settings" v-model="newTask.details" placeholder="Enter task details"></vue-editor>
          </fg-input>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Do you like doing this task?">
              <div class="elements-container">
                <div class="radio-buttons-container">
                  <input type="radio" id="energyNegative" value="-1" v-model.number="newTask.energy" name="energy"
                    class="hidden-radio">
                  <label for="energyNegative" :class="{ 'selected-image': newTask.energy === -1 }">
                    <span>Dislike</span>
                    <img src="@/assets/images/emojis-blisser-dislike.png" alt="-1" />
                  </label>
                  <input type="radio" id="energyNeutral" value="0" v-model.number="newTask.energy" name="energy"
                    class="hidden-radio">
                  <label for="energyNeutral" :class="{ 'selected-image': newTask.energy === 0 }">
                    <span>Same</span>
                    <img src="@/assets/images/emojis-blisser-same.png" alt="0" />
                  </label>
                  <input type="radio" id="energyPositive" value="1" v-model.number="newTask.energy" name="energy"
                    class="hidden-radio">
                  <label for="energyPositive" :class="{ 'selected-image': newTask.energy === 1 }">
                    <span>Love It!</span>
                    <img src="@/assets/images/emojis-blisser-love.png" alt="1" />
                  </label>
                </div>
                <div class="slider-container">
                  <el-slider v-model="newTask.energy" :min="-1" :max="1" :step="1" :show-tooltip="false"
                    @input="handleSliderChange"></el-slider>
                </div>
              </div>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Board">
              <el-select v-model="newTask.board_id" placeholder="Select board">
                <el-option v-for="board in boards" :key="board.id" :label="`${board.name}`" :value="board.id">
                </el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }">
            <fg-input label="Assignees">
              <el-select v-model="newTask.board_members" multiple placeholder="Select assignees">
                <el-option v-for="member in selectedBoardMembers" :key="member.userId" :label="`${member.name}`"
                  :value="member.userId">
                </el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <fg-input label="Due Date">
            <el-date-picker v-model="newTask.due_date" type="datetime" placeholder="Select due date"></el-date-picker>
          </fg-input>

          <ValidationProvider v-slot="{ errors }">
            <fg-input label="Project Tags">
              <el-select v-model="newTask.project_tags" multiple placeholder="Select tags">
                <el-option v-for="tag in availableTags" :key="tag" :label="tag" :value="tag"></el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <button type="submit" class="btn btn-fill btn-info" :disabled="invalid">Create Task</button>
          <button type="button" class="btn btn-fill btn-danger ml-3" @click="cancelCreation">Cancel</button>
        </form>
      </ValidationObserver>
    </card>
  </div>
</template>

<script>
import { Input, DatePicker, Select, Option, Button, Slider as ElSlider, Slider } from 'element-ui';
import { FormGroupInput as FgInput } from 'src/components/index';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useClientStore } from "@/stores/clientStore";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import SuccessNotification from "@/pages/Dashboard/Components/SuccessNotification.vue";
import { VueEditor }  from 'vue2-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  components: {
    FgInput,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ValidationProvider,
    ValidationObserver,
    ElSlider: Slider,
    VueEditor: VueEditor,
  },
  data() {
    return {
      newTask: {
        name: '',
        details: '',
        user_id: null,
        energy: 1,
        board_id: null,
        project_tags: [],
        due_date: null,
        board_members: [],
        assignees: [],
      },
      boards: [],
      availableTags: ['testing_API', 'urgent', 'high_priority', 'low_priority']
    };
  },
  watch: {
    'newTask.board_members': function (newVal) {
      this.newTask.assignees = newVal;
    }
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    },
    selectedBoardMembers() {
      const selectedBoard = this.boards.find(board => board.id === this.newTask.board_id);
      return selectedBoard ? selectedBoard.members : [];
    },
  },
  async mounted() {
    const clientStore = useClientStore();
    await clientStore.rehydrateClient();
    if (clientStore.userId && clientStore.client) {
      await this.fetchBoards();
      this.newTask.user_id = clientStore.userId;
      const routeBoardId = this.$route.params.boardId;
      if (routeBoardId && this.boards.some(board => board.id === routeBoardId)) {
        this.newTask.board_id = routeBoardId;
      }
    } else {
      this.$router.push({ name: 'login' });
    }
    this.$nextTick(() => {
      const editorContainer = this.$refs.VueEditor.$el.querySelector('.ql-container');
      if (editorContainer) {
        editorContainer.style.height = '100px';
      }
    });
  },
  methods: {
    cancelCreation() {
      this.$router.go(-1);
    },
    getUserId() {
      const clientStore = useClientStore();
      return clientStore.userId;
    },
    async fetchBoards() {
      try {
        const response = await this.client.ListAllBoards(this.getUserId());
        this.boards = response.boards;
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error fetching boards: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async submitTask() {
      try {
        if (this.newTask.due_date) {
          this.newTask.due_date = this.newTask.due_date.getTime() / 1000;
        }
        const taskToSubmit = { ...this.newTask };
        delete taskToSubmit.board_members;
        await this.client.CreateItem(taskToSubmit);
        this.$router.push({ name: 'Board View', params: { id: this.newTask.board_id } });

        this.$notify({
          component: SuccessNotification,
          icon: 'nc-icon nc-check-2',
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    handleSliderChange(value) {

    },
  }
}
</script>

<style>
.error-message {
  color: red;
  font-size: 0.875em;
}
</style>
