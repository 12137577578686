<template>
  <div class="image-container">
    <div class="image-inner-container">
      <img class="profile-image" :src="localAvatar" alt="...">
    </div>
    <div class="edit-button">
      <label>
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='M20.3788 5.37868C21.5503 4.20711 23.4499 4.20711 24.6214 5.37868C25.7931 6.55024 25.7931 8.44974 24.6214 9.62131L23.4321 10.8107L19.1895 6.56802L20.3788 5.37868Z' fill='white'/%3E%3Cpath d='M17.0681 8.68933L4.5 21.2574V25.5H8.74263L21.3106 12.932L17.0681 8.68933Z' fill='white'/%3E%3C/svg%3E">
        <input type="file" ref="fileInput" accept=".svg, .png, .jpg, .jpeg" @change="handleFileUpload" style="display: none;" />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileAvatar',
  props: {
    avatar: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localAvatar: this.avatar
    }
  },
  watch: {
    avatar(newAvatar) {
      this.localAvatar = newAvatar;
    }
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.localAvatar = e.target.result;
          this.$emit('avatar-uploaded', e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }
}
</script>
<style>
div.edit-button, div.edit-button img {
  cursor: pointer;
}

div.edit-button:hover, div.edit-button img:hover  {
  opacity: 0.9;
}

</style>