<template>
  <span><b>Task created successfully!</b></span>
</template>

<script>
export default {
  name: 'SuccessNotification'
}
</script>

<style>
</style>
