import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBp7EPTIyOq2SUtc30HoNJkEJ19q3fjGqo",
  authDomain: "itask-af736.firebaseapp.com",
  databaseURL: "https://itask-af736.firebaseio.com",
  projectId: "itask-af736",
  storageBucket: "itask-af736.appspot.com",
  messagingSenderId: "318818353341",
  appId: "1:318818353341:web:2531eab025ef44d684b7d4",
  measurementId: "G-N8G5FQCBGL",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
const analytics = getAnalytics(app);

export {
  auth,
  googleProvider,
  appleProvider,
  analytics,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
};
