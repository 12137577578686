<template>
  <div class="new-task-form">
    <card>
      <h3 class="title">Create New Board</h3>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="createBoard">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Board Name">
              <el-input v-model="newBoard.name" placeholder="Enter board name"></el-input>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Board Logo">
              <el-input v-model="newBoard.logo" placeholder="Upload logo"></el-input>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Board Type">
              <el-select v-model="newBoard.board_type" placeholder="Select board type"
                         size="large">
                <el-option v-for="boardType in availableBoardTypes"
                           class="select-danger"
                           :value="boardType"
                           :label="boardType"
                           :key="boardType">
                </el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Board Company">
              <el-select v-model="newBoard.board_company" placeholder="Select board company"
                         size="large">
                <el-option v-for="boardCompany in availableBoardCompanies"
                           class="select-danger"
                           :value="boardCompany"
                           :label="boardCompany"
                           :key="boardCompany">
                </el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Board Members">
              <el-select multiple v-model="newBoard.board_members" placeholder="Select board members"
                         size="large">
                <el-option v-for="boardMembers in availableBoardMembers"
                           class="select-danger"
                           :value="boardMembers"
                           :label="boardMembers"
                           :key="boardMembers">
                </el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <button type="submit" class="btn btn-fill btn-info" :disabled="invalid">Create Board</button>
          <button type="button" class="btn btn-fill btn-danger ml-3" @click="cancelCreation">Cancel</button>
        </form>
      </ValidationObserver>
    </card>
  </div>
</template>

<script>
import {Input, Select, Option, Button} from 'element-ui';
import {FormGroupInput as FgInput} from 'src/components/index';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import {useClientStore} from "@/stores/clientStore";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import { EventBus } from '@/eventBus.js';
import SuccessNotification from "@/pages/Dashboard/Components/SuccessNotification.vue";

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  components: {
    FgInput,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      newBoard: {
        name: '',
        logo: '',
        board_type: '',
        board_company: '',
        board_members: [],
      },
      availableBoardTypes: ['family', 'me', 'organization'],
      availableBoardCompanies: ['company1', 'company2', 'company3'],
      availableBoardMembers: ['member1', 'member2', 'member3']
    };
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    }
  },
  async mounted() {
    const clientStore = useClientStore();
    await clientStore.rehydrateClient();
    if (clientStore.userId && clientStore.client) {
      this.newTask.user_id = clientStore.userId;
    } else {
      this.$router.push({name: 'login'});
    }
  },
  methods: {
    cancelCreation() {
      this.$router.go(-1);
    },
    getUserId() {
      const clientStore = useClientStore();
      return clientStore.userId;
    },
    async createBoard() {
      try {
        await this.client.CreateBoard(this.getUserId(),this.newBoard);
        EventBus.$emit('boardCreated');
        this.$router.push({name: 'admin'});
        this.$notify({
          component: SuccessNotification,
          message: 'Board created successfully',
          icon: 'nc-icon nc-check-2',
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
  }
}
</script>

<style>
.error-message {
  color: red;
  font-size: 0.875em;
}
</style>
