<template>
  <ul class="nav nav-mobile-menu">
    <form class="navbar-form navbar-left navbar-search-form" role="search">
      <div class="input-group">
        <i class="nc-icon nc-zoom-split"></i>
        <input type="text" value="" class="form-control" placeholder="Search...">
      </div>
    </form>
    <drop-down>
      <template slot="title">
        <i class="nc-icon nc-bell-55"></i>
        <span class="notification">5 </span>
        <span class="d-lg-none">Notification</span>
      </template>
      <a class="dropdown-item" href="#">Notification 1</a>
      <a class="dropdown-item" href="#">Notification 2</a>
      <a class="dropdown-item" href="#">Notification 3</a>
      <a class="dropdown-item" href="#">Notification 4</a>
      <a class="dropdown-item" href="#">Another notification</a>
    </drop-down>

    <drop-down>
      <template slot="title">
        <i class="nc-icon nc-bullet-list-67"></i>
        <span class="d-lg-none">Configuration</span>
      </template>
      <a class="dropdown-item" href="#">
        <i class="nc-icon nc-umbrella-13"></i> Help Center
      </a>
      <a class="dropdown-item" href="#">
        <i class="nc-icon nc-email-85"></i> Messages
      </a><a class="dropdown-item" href="#">
      <i class="nc-icon nc-settings-90"></i> Settings
    </a>
      <div class="divider"></div>
      <a href="#" @click="logout" class="dropdown-item text-danger">
        <i class="nc-icon nc-button-power"></i> Log out
      </a>
    </drop-down>
  </ul>
</template>
<script>
import DropDown from 'src/components/Dropdown.vue'
import {auth, signOut} from "@/firebase";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";

export default {
  components: {
    DropDown
  },
  methods: {
    logout() {
      signOut(auth)
        .then(() => {
          sessionStorage.clear();
          this.$router.push({name: 'login'});
        })
        .catch((error) => {
          this.$notify({
            component: FailureNotification,
            message: `Error: ${error.message}`,
            icon: 'nc-icon nc-alert-circle-i',
            type: 'danger'
          });
        });
    }
  }
}
</script>
<style>
</style>
