import Vue from "vue";
import VueRouter from "vue-router";
import LightBootstrap from "./light-bootstrap-main";
import App from "./App.vue";

import {getAuth, onAuthStateChanged} from "firebase/auth";
import {auth} from "./firebase";
import {createPinia, PiniaVuePlugin} from 'pinia'
import {useClientStore} from './stores/clientStore';
import Cookies from 'js-cookie';

// router setup
import routes from "./routes/routes";
import 'element-ui/lib/theme-chalk/index.css';

// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

// configure router
const router = new VueRouter({
  routes,
  linkActiveClass: "active",
});

let app;
let user;

// Firebase Auth Check
onAuthStateChanged(auth, async (currentUser) => {
  user = currentUser;

  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      pinia,
      render: (h) => h(App),
    });

    const clientStore = useClientStore();
    const userId = Cookies.get('userId');
    const token = Cookies.get('token');
    if (userId && token) {
      clientStore.setUserId(userId);
      clientStore.setToken(token);
    }
    clientStore.rehydrateClient();
  }
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user) {
      next();
    } else {
      next({path: "/login", query: {redirect: to.fullPath}});
    }
  } else {
    next();
  }
});