<template>
  <div class="card">
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>
    <div class="card-header d-flex justify-content-between align-items-center" v-if="$slots.header || title">
      <div>
        <slot name="header">
          <h4 class="card-title">{{ title }}</h4>
          <p class="card-category">{{ subTitle }}</p>
        </slot>
      </div>
      <router-link v-if="buttonPath" :to="buttonPath" class="btn btn-primary">
        {{ buttonText }}
      </router-link>
      <button v-if="onButtonClick" class="btn btn-primary" @click="onButtonClick">
        {{ buttonText }}
      </button>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
    <div class="card-footer" v-if="$slots.footer">
      <hr>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'card',
    props: {
      title: String,
      subTitle: String,
      buttonPath: String,
      buttonText: String,
      onButtonClick: Function,
    }
  }
</script>
<style>
</style>
