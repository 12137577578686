<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <LAlert v-if="showError" type="danger" dismissible>
          {{ errorMessage }}
        </LAlert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                    <fg-input type="email" :error="failed ? 'The Email field is required' : null" :hasSuccess="passed"
                      label="Email address" name="email" v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider name="password" rules="required|min:5" v-slot="{ passed, failed }">
                    <fg-input type="password" :error="failed ? 'The Password field is required' : null"
                      :hasSuccess="passed" name="password" label="Password" v-model="password">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button type="submit" class="login-button">Login</button>
                  <br>
                  <button type="button" class="gsi-material-button" @click="loginWithGoogle">
                    <div class="gsi-material-button-state"></div>
                    <div class="gsi-material-button-content-wrapper">
                      <div class="gsi-material-button-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
                          xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                          <path fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z">
                          </path>
                          <path fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z">
                          </path>
                          <path fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z">
                          </path>
                          <path fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                          </path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                      <span class="gsi-material-button-contents">Sign in with Google</span>
                      <span style="display: none;">Sign in with Google</span>
                    </div>
                  </button>
                  <button type="button" class="btn btn-apple" @click="loginWithApple">
                    <i class="fa fa-apple"></i>Sign in with Apple
                  </button>
                  <div class="forgot">
                    <router-link to="/register" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import { FadeRenderTransition } from 'src/components/index';
import AuthLayout from './AuthLayout.vue';
import { extend } from "vee-validate";
import { email, min, required } from "vee-validate/dist/rules";
import { appleProvider, auth, googleProvider, signInWithEmailAndPassword, signInWithPopup } from '@/firebase.js';
import LAlert from "@/components/Alert.vue";
import { useClientStore } from '@/stores/clientStore';
import NotificationSample from "@/pages/Dashboard/Components/NotificationSample.vue";
import Cookies from 'js-cookie';

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LAlert,
    FadeRenderTransition,
    AuthLayout
  },
  computed: {
    clientStore() {
      return useClientStore();
    },
    client() {
      return this.clientStore.client;
    }
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      errorMessage: '',
      showError: false,
    };
  },
  methods: {
    async submit() {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        this.clientStore.initializeClient(userCredential.user.accessToken);
        await this.handleAuthentication(userCredential.user.email, userCredential.user.accessToken);
      } catch (error) {
        this.handleError(error);
      }
    },
    async loginWithGoogle() {
      try {
        const result = await signInWithPopup(auth, googleProvider);
        this.clientStore.initializeClient(result.user.accessToken);
        await this.handleAuthentication(result.user.email, result.user.accessToken);
      } catch (error) {
        this.handleError(error);
      }
    },
    async loginWithApple() {
      try {
        const result = await signInWithPopup(auth, appleProvider);
        this.clientStore.initializeClient(result.user.accessToken);
        await this.handleAuthentication(result.user.email, result.user.accessToken);
      } catch (error) {
        this.handleError(error);
      }
    },
    async handleAuthentication(email, token) {
      try {
        await this.client.Register(email, token);
        const response = await this.client.Login(email, token);
        this.updateStoreAndNavigate(response.userId, response.token);
      } catch (error) {
        try {
          const response = await this.client.Login(email, token);
          this.updateStoreAndNavigate(response.userId, response.token);
        } catch (registerError) {
          this.handleError(registerError);
        }
      }
    },
    updateStoreAndNavigate(userId, token) {
      this.clientStore.setUserId(userId);
      this.clientStore.setToken(token);
      
      Cookies.set('userId', userId, { sameSite: 'Lax', secure: true });
      Cookies.set('token', token, { sameSite: 'Lax', secure: true });

      this.$router.push({ name: 'admin' });
      this.$notify(
        {
          component: NotificationSample,
          icon: 'nc-icon nc-app'
        })
    },
  },
  mounted() {
  },
  toggleNavbar() {
    document.body.classList.toggle('nav-open')
  },
  closeMenu() {
    document.body.classList.remove('nav-open')
    document.body.classList.remove('off-canvas-sidebar')
  }
}
</script>

<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.login-button {
  width: 185px;
  background-color: #7eb829;
  transition: all 0.3s, color 0.3s;
  font-weight: 400;
  padding: 8px 16px;
  border: 1px solid #7eb829;
  color: #FFFFFF;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 1.42857143;
}

.login-button:hover {
  background-color: #7ab535;
  border-color: #7ab535;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  margin-bottom: 6px;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.btn-apple {
  background-color: black;
  color: white;
  border: none;
  font-family: 'Arial', sans-serif;
  padding: 10px 15px;
  font-size: 14px;
  letter-spacing: 0.25px;
  transition: background-color 0.3s, color 0.3s;
  width: 185px;
}

.btn-apple:hover {
  background-color: #333;
}
</style>
