<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <card class="card-tasks" title="Tasks" :subTitle="boardTitle || 'Loading...'" :on-button-click="createTaskPath"
          buttonText="Add New Task">
          <draggable v-if="statusesLoaded" v-model="tableData.data" @end="onDragEnd" class="dragArea">
            <div v-for="row in filteredItems" :key="row.id" class="dragItem">
              <div class="dragColumn text-column" @click="editItem(row.id)">
                {{ row.title }}
              </div>
              <div class="dragColumn avatar-column">
                <avatar :size="30"
                  :src="row.avatar ? row.avatar : 'https://i.postimg.cc/zD2Dmjqq/360-F-516275801-f3-Fsp17x6-HQK0x-Qg-DQEELo-Tu-ERO4-Ss-WV.jpg'">
                </avatar>
              </div>
              <multiselect class="custom-multiselect" v-if="showMultiselect[row.id]" v-model="row.status"
                :options="boardStatuses" placeholder="Select a status" :custom-label="statusCustomLabel"
                :custom-option="statusCustomOption" @input="onStatusChange($event, row)" :style="{
                  backgroundColor: row.status ? row.status.color : '',
                  color: row.status && row.status.color !== '#000000' && row.status.color !== '#FFFFFF' ? '#FFFFFF' : '#000000'
                }">
                <template slot="option" slot-scope="{ option }">
                  <span :class="{ 'option-selected': row.status === option }"
                    :style="{ backgroundColor: option.color, color: option.color !== '#000000' && option.color !== '#FFFFFF' ? '#FFFFFF' : '#000000' }">
                    {{ option.name }}
                  </span>
                </template>
              </multiselect>
              <EditStatusModal v-model="isEditStatusModalVisible[row.id]" @closeModalEvent="updateItemStatus(row.id)"
                @saveModalEvent="refreshDraggable" :statuses="boardStatuses" :userId="userId" :boardId="boardId">
              </EditStatusModal>
              <div class="dragColumn td-actions">
                <div class="btn btn-danger btn-simple btn-link" v-tooltip.top-center="deleteTooltip"
                  @click="deleteItem(row.id)">
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>
          </draggable>
          <div class="stats" slot="footer">
            <i class="fa fa-history"></i> Updated 3 minutes ago
            <button type="button" class="btn btn-fill btn-danger ml-3" @click="deleteBoard">Delete Board</button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card } from '@/components';
import { useClientStore } from '@/stores/clientStore';
import { EventBus } from '@/eventBus.js';
import Swal from 'sweetalert2';
import SuccessNotification from "@/pages/Dashboard/Components/SuccessNotification.vue";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import draggable from 'vuedraggable';
import Avatar from 'vue-avatar'
import Multiselect from 'vue-multiselect'
import EditStatusModal from '@/pages/Dashboard/Components/EditStatusModal.vue';

export default {
  components: {
    Card,
    draggable,
    Avatar,
    Multiselect,
    EditStatusModal
  },
  data() {
    return {
      editTooltip: 'Edit Task',
      deleteTooltip: 'Remove',
      userId: null,
      boardId: null,
      assignees: [],
      boardTitle: null,
      boardStatuses: [],
      tableData: {
        data: [],
      },
      searchQuery: '',
      selectedStatus: null,
      isEditStatusModalVisible: {},
      showMultiselect: {},
      selectedItems: [],
      statusesLoaded: false,
      boardMembers: []
    }
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    },
    filteredItems() {
      if (!this.searchQuery) {
        return this.tableData.data;
      }
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      return this.tableData.data.filter(item =>
        item.title && item.title.toLowerCase().includes(lowerCaseQuery) ||
        item.detail && item.detail.toLowerCase().includes(lowerCaseQuery)
      );
    }
  },
  async mounted() {
    const clientStore = useClientStore();
    await clientStore.rehydrateClient();
    if (clientStore.userId && clientStore.client) {
      this.boardId = this.$route.params.id;
      this.userId = this.getUserId();
      await this.getBoardInfo();
      await this.loadItems();
    } else {
      this.$router.push({ name: 'login' });
    }
    EventBus.$on('searchQueryChanged', (newQuery) => {
      this.searchQuery = newQuery;
    });
  },
  beforeDestroy() {
    EventBus.$off('searchQueryChanged');
  },
  watch: {
    '$route.params.id': {
      async "handler"(newId) {
        this.boardId = newId;
        if (this.client) {
          await this.getBoardInfo();
          this.loadItems();
        }
      },
      "immediate": true
    },
  },
  methods: {
    statusCustomLabel(status) {
      return status.name;
    },
    statusCustomOption(status) {
      return {
        label: status.name,
        key: status.id,
        $isLabel: true,
        $isDisabled: false,
      };
    },
    openEditStatusModal(item) {
      this.isEditStatusModalVisible[this.selectedItem.id] = true;
      this.showMultiselect[this.selectedItem.id] = false;
      this.selectedItems.push(item);
    },
    onDragEnd(event) {
      const oldIndex = event.oldIndex;
      const newIndex = event.newIndex;

      let previousItemId = null;
      let nextItemId = null;

      const draggedItemId = this.tableData.data[newIndex].id;

      // Check if the dragged item is not at the beginning of the list
      if (newIndex > 0) {
        previousItemId = this.tableData.data[newIndex - 1].id;
      }

      // Check if the dragged item is not at the end of the list
      if (newIndex < this.tableData.data.length - 1) {
        nextItemId = this.tableData.data[newIndex + 1].id;
      }

      this.reorderItem(draggedItemId, previousItemId, nextItemId);
    },
    createTaskPath() {
      this.$router.push({ name: 'Create Task', params: { boardId: this.boardId } });
    },
    async getBoardInfo() {
      try {
        const response = await this.client.GetBoardById(this.getUserId(), this.boardId);
        this.boardTitle = response.board.name;
        this.boardStatuses = response.board.statusList;
        this.statusesLoaded = true;
        this.boardMembers = response.board.members;
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async getUserInfo() {
      try {
        const response = await this.client.GetUser(this.getUserId());
        return response.user
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async loadItems() {
      try {
        const userId = this.getUserId();
        const response = await this.client.ListAllItems(userId, this.boardId);
        const itemsData = response.items;
        itemsData.forEach(item => {
          if (item.assignees) {
            item.assignees.forEach(assignee => {
              const member = this.boardMembers.filter(member => member.userId === assignee);
              if (member.length > 0) {
                item.avatar = member[0].avatar;
              }
            });
          }
        });
        const newData = itemsData.map(item => {
          const status = this.boardStatuses.find(status => status.id === item.statusId);
          this.showMultiselect[item.id] = true;
          return {
            id: item.id,
            title: item.name,
            userId: item.userId,
            checked: item.done,
            assignees: item.assignees,
            avatar: item.avatar || '',
            statusId: item.statusId,
            status: status,
          };
        });
        this.$set(this.tableData, 'data', newData);
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async loadItem(itemId) {
      try {
        const userId = this.getUserId();
        const response = await this.client.FetchItem(userId, itemId);
        const itemData = response.item;
        return itemData;
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    getUserId() {
      const clientStore = useClientStore();
      return clientStore.userId;
    },
    editItem(itemId) {
      this.$router.push({ name: 'Update Task', params: { id: itemId } });
    },
    async deleteItem(itemId) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this item!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#D33',
          confirmButtonColor: '#3085D6'
        });

        if (result.isConfirmed) {
          const userId = this.getUserId();
          await this.client.DeleteItem(userId, itemId);
          await this.loadItems();
          Swal.fire(
            'Deleted!',
            'Your item has been deleted.',
            'success'
          );
        }
      } catch (error) {
        Swal.fire(
          'Failed!',
          'There was an error deleting your item.',
          'error'
        )
      }
    },
    async reorderItem(draggedItemId, previousItemId, nextItemId) {
      try {
        await this.client.ReorderItem(
          this.getUserId(),
          draggedItemId,
          {
            previous_item_id: previousItemId,
            next_item_id: nextItemId
          }
        )

        this.$notify({
          component: SuccessNotification,
          message: 'Items reordered successfully',
          icon: 'nc-icon nc-check-2',
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async deleteBoard() {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this board!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#D33',
          confirmButtonColor: '#3085D6'
        });

        if (result.isConfirmed) {
          const userId = this.getUserId();
          await this.client.DeleteBoard(userId, this.boardId);
          Swal.fire(
            'Deleted!',
            'Your board has been deleted.',
            'success'
          ).then(() => {
            window.location.reload(true);
          });
          this.$router.push({ name: 'admin' });
        }
      } catch (error) {
        Swal.fire(
          'Failed!',
          error.message,
          'error'
        )
      }
    },
    async updateItemStatus(itemId) {
      for (let selectedItem of this.selectedItems) {
        const updatedItem = await this.loadItem(selectedItem.id);
        const updatedStatus = this.boardStatuses.find(status => status.id === updatedItem.statusId);
        const index = this.tableData.data.findIndex(item => item.id === updatedItem.id);
        if (index !== -1) {
          this.$set(this.tableData.data, index, {
            ...this.tableData.data[index],
            statusId: updatedItem.statusId,
            status: updatedStatus
          });
        }
      }
      this.showMultiselect[itemId] = true;
    },
    async onStatusChange(newStatus, row) {
      if (newStatus === null) {
        try {
          await this.client.UpdateItem(this.getUserId(), row.id, { statusId: '' });
          this.$notify({
            component: SuccessNotification,
            message: 'Task updated successfully',
            icon: 'nc-icon nc-check-2',
            type: 'success'
          });
        } catch (error) {
          this.$notify({
            component: FailureNotification,
            message: `Error: ${error.message}`,
            icon: 'nc-icon nc-alert-circle-i',
            type: 'danger'
          });
        }
      } else if (newStatus.name === 'Edit Label') {
        this.selectedItem = row;
        this.openEditStatusModal(row);
      } else {
        try {
          await this.client.UpdateItem(this.getUserId(), row.id, { statusId: newStatus.id });
          this.$notify({
            component: SuccessNotification,
            message: 'Task updated successfully',
            icon: 'nc-icon nc-check-2',
            type: 'success'
          });
        } catch (error) {
          this.$notify({
            component: FailureNotification,
            message: `Error: ${error.message}`,
            icon: 'nc-icon nc-alert-circle-i',
            type: 'danger'
          });
        }
      }
    },
    async refreshDraggable() {
      await this.getBoardInfo();
      await this.loadItems();
      this.isEditStatusModalVisible = {};
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
