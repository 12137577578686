<template>
  <card class="profile-form">
    <div>
      <form>
        <div class="row">
          <div class="col-md-12 row-size">
            <fg-input type="text" label="Full Name" placeholder="Full Name" v-model="user.name">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 row-size">
            <fg-input type="email" label="Email Address" placeholder="tania@meta.com" disabled v-model="user.email">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <button type="button" class="btn cancel-button" @click="cancel">Cancel</button>
          </div>
          <div class="col-md-6">
            <button type="submit" class="btn btn-fill btn-wd save-button" @click.prevent="save">
              Save
            </button>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
export default {
  props: {
      email: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      }
    },
  data() {
    return {
      user: {
        name: this.name,
        email: this.email,
      }
    }
  },
  mounted() {
  },
  methods: {
    cancel() {
      this.$router.push({name: 'admin'});;
    },
    save() {
      this.$emit('update', this.user);
    }
  }
}

</script>
<style></style>
