<template>
  <div class="border-gradient">
    <div class="pop-up-container">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopUp'
}
</script>

<style></style>
