import axios from 'axios';
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import {mockedOrganizationsResponse} from "@/services/mocked";

class BlisserClient {
  constructor(token) {
    if (!token) {
      throw new Error('Token is required and cannot be empty or undefined');
    }
    this.token = token;

    this.baseURL = window.location.hostname === 'localhost' ? 'http://localhost:8002/v1' : 'https://api.blisser.app/v1';

    this.apiClient = axios.create({
      baseURL: this.baseURL,
    });
  }

  async InitializeFirebase(firebaseConfig) {
    if (!this.firebase) {
      this.firebase = require('firebase/app');
      require('firebase/auth');
      require('firebase/firestore');
    }
    this.firebase.initializeApp(firebaseConfig);
  }

  async Login(email, token) {
    try {
      const response = await this.apiClient.post('/social-login', {email, token});
      this.userId = response.data.userId;
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Login error: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async Register(email, firebase_token) {
    try {
      const response = await this.apiClient.post('/register', {email, firebase_token});
      this.userId = response.data.userId;
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Register error: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async CreateItem(itemData) {
    try {
      const response = await this.apiClient.post('/item', itemData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error creating item: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async ListAllItems(userId, boardId) {
    try {
      const response = await this.apiClient.get(`/user/${userId}/items/${boardId}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error listing items: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async UpdateItem(userId, itemId, updateData) {
    try {
      const response = await this.apiClient.put(`/user/${userId}/item/${itemId}`, updateData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error updating item: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async ReorderItem(userId, itemId, updateData) {
    try {
      const response = await this.apiClient.put(`/user/${userId}/item/${itemId}/reorder`, updateData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error updating item: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async DeleteItem(userId, itemId) {
    try {
      const response = await this.apiClient.delete(`/user/${userId}/item/${itemId}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error deleting item: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async FetchItem(userId, itemId) {
    try {
      const response = await this.apiClient.get(`/user/${userId}/item/${itemId}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `'Error fetching item:: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async CreateBoard(userId, boardData) {
    try {
      const response = await this.apiClient.post(`/user/${userId}/boards`, boardData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `'Error creating board: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async UpdateBoard(userId, boardId, updateData) {
    try {
      const response = await this.apiClient.put(`/user/${userId}/boards/${boardId}`, updateData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error updating board: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async ListAllBoards(userId) {
    try {
      const response = await this.apiClient.get(`/user/${userId}/boards`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `'Error listing boards: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async GetBoardById(userId, boardId) {
    try {
      const response = await this.apiClient.get(`/user/${userId}/boards/${boardId}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `'Error listing boards: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async ListAllOrganizations(userId) {
    try {
      const response = await this.apiClient.get(`/user/${userId}/organizations-board-tree`,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `'Error listing boards: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async DeleteBoard(userId, boardId) {
    try {
      const response = await this.apiClient.delete(`/user/${userId}/boards/${boardId}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async GetUser(userId) {
    try {
      const response = await this.apiClient.get(`/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `'Error fetching user: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }

  async UpdateUser(userId, updateData) {
    try {
      const response = await this.apiClient.put(`/user/${userId}`, updateData, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error updating item: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      throw error;
    }
  }
}



export default BlisserClient;
