<template>
  <span>Welcome to <b>Blisser</b></span>
</template>
<script>
export default {
  name: 'notification-sample'
}
</script>
<style>
</style>
