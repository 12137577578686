<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <card class="card-tasks" title="Tasks" :subTitle="boardTitle || 'Loading...'" :on-button-click="createTaskPath"
          buttonText="Add New Task">
          <template v-slot:custom-button>
            <button @click="addNewTask">Add New Task</button>
          </template>
          <draggable v-model="tableData.data" @end="onDragEnd" class="dragArea">
            <div v-for="row in tableData.data" :key="row.id" class="dragItem">
              <div class="dragColumn checkbox-container">
                <Checkbox :checked="row.checked" @change="toggleTask(row.id)"></Checkbox>
              </div>
              <div class="dragColumn text-column">
                <text-modal :text="row.title" :taskId="row.id" />
              </div>
              <div class="dragColumn td-actions d-flex justify-content-end">
                <div class="btn btn-info btn-simple btn-link" v-tooltip.top-center="editTooltip"
                  @click="editItem(row.id)">
                  <i class="fa fa-edit"></i>
                </div>
                <div class="btn btn-danger btn-simple btn-link" v-tooltip.top-center="deleteTooltip"
                  @click="deleteItem(row.id)">
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>
          </draggable>
          <div class="stats" slot="footer">
            <i class="fa fa-history"></i> Updated 3 minutes ago
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, Checkbox, Table as LTable } from 'src/components/index';
import { useClientStore } from '@/stores/clientStore';
import Swal from 'sweetalert2';
import SuccessNotification from "@/pages/Dashboard/Components/SuccessNotification.vue";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import TextModal from "@/pages/Dashboard/Components/TextModal.vue";
import draggable from 'vuedraggable';

export default {
  components: {
    TextModal,
    Checkbox,
    Card,
    draggable,
  },
  data() {
    return {
      editTooltip: 'Edit Task',
      deleteTooltip: 'Remove',
      boardId: "",
      boardTitle: null,
      tableData: {
        data: [],
      },
    }
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    }
  },
  async mounted() {
    const clientStore = useClientStore();
    await clientStore.rehydrateClient();
    if (clientStore.userId && clientStore.client) {
      await this.fetchFirstBoard()
      await this.getBoardInfo();
      await this.loadItems();
    } else {
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    createTaskPath() {
      this.$router.push({ name: 'Create Task', params: { boardId: this.boardId } });
    },
    onDragEnd() {
      // Stub for onDragEnd method
    },
    async fetchFirstBoard() {
      try {
        const response = await this.client.ListAllBoards(this.getUserId());
        this.boardId = response.boards[0].id;
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error fetching boards: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async toggleTask(itemId) {
      try {
        const userId = this.getUserId();
        await this.client.ToggleTask(userId, itemId);
        this.$notify({
          component: SuccessNotification,
          message: 'Task status changed successfully',
          icon: 'nc-icon nc-check-2',
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async getBoardInfo() {
      try {
        const response = await this.client.GetBoardById(this.getUserId(), this.boardId);
        this.boardTitle = response.board.name;
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async loadItems() {
      try {
        const userId = this.getUserId();
        const response = await this.client.ListAllItems(userId, this.boardId);
        const itemsData = response.items;
        this.tableData.data = itemsData.map(item => ({
          id: item.id,
          title: item.name,
          checked: item.done,
        }));
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    getUserId() {
      const clientStore = useClientStore();
      return clientStore.userId;
    },
    editItem(itemId) {
      this.$router.push({ name: 'Update Task', params: { id: itemId } });
    },
    async deleteItem(itemId) {
      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this item!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#D33',
          confirmButtonColor: '#3085D6'
        });

        if (result.isConfirmed) {
          const userId = this.getUserId();
          await this.client.DeleteItem(userId, itemId);
          await this.loadItems();
          Swal.fire(
            'Deleted!',
            'Your item has been deleted.',
            'success'
          );
        }
      } catch (error) {
        Swal.fire(
          'Failed!',
          'There was an error deleting your item.',
          'error'
        )
      }
    },
  }
}
</script>
<style></style>
