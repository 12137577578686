// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";

// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
import Stats from "src/pages/Dashboard/Dashboard/Stats.vue";
import CreateTask from "src/pages/Dashboard/Dashboard/Task/CreateTask.vue";
import UpdateTask from "src/pages/Dashboard/Dashboard/Task/UpdateTask.vue";
import CreateBoard from "@/pages/Dashboard/Dashboard/Board/CreateBoard.vue";
import BoardView from "@/pages/Dashboard/Dashboard/Board/BoardView.vue";

// Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
// import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from "src/pages/Dashboard/Pages/Login.vue";
import Register from 'src/pages/Dashboard/Pages/Register.vue'

// Components pages
// import Buttons from 'src/pages/Dashboard/Components/Buttons.vue'
// import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue'
// import Panels from 'src/pages/Dashboard/Components/Panels.vue'
// import Notifications from 'src/pages/Dashboard/Components/Notifications.vue'
// import Icons from 'src/pages/Dashboard/Components/Icons.vue'
// import Typography from 'src/pages/Dashboard/Components/Typography.vue'
//
// const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue')
//
// // Forms pages
// const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue')
// const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue')
// const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue')
// const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue')

// // TableList pages
// const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue')
// const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue')
// const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue')
// // Maps pages
// const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue')
// const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue')
// const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue')
//
// Calendar
const Calendar = () => import("src/pages/Dashboard/Calendar/CalendarRoute.vue");
// Charts
const Charts = () => import("src/pages/Dashboard/Charts.vue");

// let componentsMenu = {
//   path: '/components',
//   component: DashboardLayout,
//   redirect: '/components/buttons',
//   children: [
//     {
//       path: 'buttons',
//       name: 'Buttons',
//       component: Buttons
//     },
//     {
//       path: 'grid-system',
//       name: 'Grid System',
//       component: GridSystem
//     },
//     {
//       path: 'panels',
//       name: 'Panels',
//       component: Panels
//     },
//     {
//       path: 'sweet-alert',
//       name: 'Sweet Alert',
//       component: SweetAlert
//     },
//     {
//       path: 'notifications',
//       name: 'Notifications',
//       component: Notifications
//     },
//     {
//       path: 'icons',
//       name: 'Icons',
//       component: Icons
//     },
//     {
//       path: 'typography',
//       name: 'Typography',
//       component: Typography
//     }
//
//   ]
// }
// let formsMenu = {
//   path: '/forms',
//   component: DashboardLayout,
//   redirect: '/forms/regular',
//   children: [
//     {
//       path: 'regular',
//       name: 'Regular Forms',
//       component: RegularForms
//     },
//     {
//       path: 'extended',
//       name: 'Extended Forms',
//       component: ExtendedForms
//     },
//     {
//       path: 'validation',
//       name: 'Validation Forms',
//       component: ValidationForms
//     },
//     {
//       path: 'wizard',
//       name: 'Wizard',
//       component: Wizard
//     }
//   ]
// }
//
// let tablesMenu = {
//   path: '/table-list',
//   component: DashboardLayout,
//   redirect: '/table-list/regular',
//   children: [
//     {
//       path: 'regular',
//       name: 'Regular Tables',
//       component: RegularTables
//     },
//     {
//       path: 'extended',
//       name: 'Extended Tables',
//       component: ExtendedTables
//     },
//     {
//       path: 'paginated',
//       name: 'Paginated Tables',
//       component: PaginatedTables
//     }]
// }
//
// let mapsMenu = {
//   path: '/maps',
//   component: DashboardLayout,
//   redirect: '/maps/google',
//   children: [
//     {
//       path: 'google',
//       name: 'Google Maps',
//       component: GoogleMaps
//     },
//     {
//       path: 'full-screen',
//       name: 'Full Screen Map',
//       component: FullScreenMap
//     },
//     {
//       path: 'vector-map',
//       name: 'Vector Map',
//       component: VectorMaps
//     }
//   ]
// }
//
// let pagesMenu = {
//   path: '/pages',
//   component: DashboardLayout,
//   redirect: '/pages/user',
//   children: [
//     {
//       path: 'user',
//       name: 'User Page',
//       component: User
//     },
//     {
//       path: 'timeline',
//       name: 'Timeline Page',
//       component: TimeLine
//     }
//   ]
// }
//
// let loginPage = {
//   path: '/login',
//   name: 'Login',
//   component: Login
// }
//
// let registerPage = {
//   path: '/register',
//   name: 'Register',
//   component: Register
// }
//


const Home = {template: "<div>Home</div>"};
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/admin",
    component: DashboardLayout,
    name: "admin",
    redirect: "/admin/overview",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview,
      },
      {
        path: "profile",
        name: "Profile",
        component: User,
      },
      {
        path: "stats",
        name: "Stats",
        component: Stats,
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
      },
      {
        path: "create-task",
        name: "Create Task",
        component: CreateTask,
      },
      {
        path: "update-task/:id",
        name: "Update Task",
        component: UpdateTask,
      },
      {
        path: "create-board",
        name: "Create Board",
        component: CreateBoard,
      },
      {
        path: "board/:id",
        name: "Board View",
        component: BoardView,
      }
    ],
  },
  // componentsMenu,
  // formsMenu,
  // tablesMenu,
  // mapsMenu,
  // pagesMenu,
  // loginPage,
  // registerPage,
  {path: "*", component: NotFound},
];

export default routes
