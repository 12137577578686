<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/admin/overview'}"/>
        <sidebar-item :link="{name: 'Create Board', icon: 'nc-icon nc-simple-add', path: '/admin/create-board'}"/>
        <sidebar-item :link="{name: 'My Boards', icon: 'nc-icon nc-grid-45'}">
          <sidebar-item
            v-for="board in boards"
            :key="board.id"
            :link="{name: board.name, path: `/admin/board/${board.id}`}">
          </sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'My Organizations', icon: 'nc-icon nc-layers-3'}">
          <sidebar-organization-item
            v-for="organization in organizations"
            :key="organization.id"
            :organization="organization">
          </sidebar-organization-item>
        </sidebar-item>

      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import {useClientStore} from "@/stores/clientStore";
import {EventBus} from '@/eventBus.js';
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import SidebarOrganizationItem from "@/components/SidebarPlugin/SidebarOrganizationItem.vue";


function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    SidebarOrganizationItem,
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    },
  },
  created() {
    EventBus.$on('boardCreated', this.fetchBoards);
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    getUserId() {
      const clientStore = useClientStore();
      return clientStore.userId;
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('main-panel');
        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }, async fetchBoards() {
      try {
        const response = await this.client.ListAllBoards(this.getUserId());
        this.boards = response.boards;
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error fetching boards: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async fetchOrganizations() {
      try {
        const response = await this.client.ListAllOrganizations(this.getUserId());
        this.organizations = response.organizations;
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error fetching organizations: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    }
  },
  data() {
    return {
      boards: [],
      organizations: [],
    };
  },
  async mounted() {
    const clientStore = useClientStore();
    await clientStore.rehydrateClient();
    if (clientStore.userId && clientStore.client) {
      await this.fetchBoards();
      await this.fetchOrganizations();
    } else {
      this.$router.push({name: 'login'});
    }
    this.initScrollbar();

  },
}

</script>
