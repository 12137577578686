<template>
  <div v-if="value">
    <div v-for="(status, index) in localStatuses.slice(0, -1)" :key="index" class="status-row" id="color-selector">
      <multiselect id="change" v-model="status.color" :options="colors" :showLabels="false" :allow-empty="false">
        <template slot="singleLabel" slot-scope="{ option }">
          <div class="color-square" :style="{ backgroundColor: option }"></div>
        </template>
        <template slot="option" slot-scope="{ option }">
          <div class="color-square" :style="{ backgroundColor: option }" v-hover="updateHoveredColor"></div>
        </template>
        <template slot="multiselect__spinner">
          <div class="color-square" ref="spinner"></div>
        </template>
      </multiselect>
      <input type="text" v-model="status.name">
    </div>
    <button @click="saveChanges">Save Changes</button>
    <button @click="closeModal">Close</button>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect'
import { useClientStore } from '@/stores/clientStore';
import SuccessNotification from "@/pages/Dashboard/Components/SuccessNotification.vue";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";

export default {
  components: { Multiselect },
  watch: {
    statuses: {
      handler(newStatuses) {
        this.localStatuses = JSON.parse(JSON.stringify(newStatuses));
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      hoveredColor: null,
      hoverTimeout: null,
      colors: [
        '#6F2DA8', '#324AB2', '#120A8F', '#007A74', '#00A550', '#FFBA00', '#44362F', '#A81C07', '#F04A00', '#E25822', '#1B1B1B', '#32174D'
      ],
      localStatuses: []
    }
  },
  props: {
    value: Boolean,
    statuses: Array,
    userId: String,
    boardId: String,
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    },
  },
  mounted() {
    this.originalStatuses = JSON.parse(JSON.stringify(this.statuses));
  },
  methods: {
    updateHoveredColor(color) {
      clearTimeout(this.hoverTimeout);
      this.hoveredColor = color;
      if (this.$refs.spinner) {
        this.$refs.spinner.style.backgroundColor = color;
      }
    },
    async saveChanges() {
      try {
        const statusList = this.localStatuses.map(status => ({
          id: status.id,
          name: status.name,
          color: status.color
        }));

        const updateData = { statusList };
        await this.client.UpdateBoard(this.userId, this.boardId, updateData);

        this.$notify({
          component: SuccessNotification,
          message: 'Statuses updated successfully',
          icon: 'nc-icon nc-check-2',
          type: 'success'
        });
        this.$emit('saveModalEvent');
        this.closeModal();
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error updating statuses: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    closeModal() {
      this.localStatuses = JSON.parse(JSON.stringify(this.originalStatuses));

      this.$emit('input', false);
      this.$emit('closeModalEvent');
    }
  },
  directives: {
    hover: {
      bind(el, binding, vnode) {
        el.addEventListener('mouseover', (event) => {
          clearTimeout(vnode.context.hoverTimeout); // Clear the timeout
          if (event.target.classList.contains('color-square')) {
            let color = event.target.style.backgroundColor;
            binding.value(color);
            let spinners = document.querySelectorAll('.multiselect__spinner');
            spinners.forEach(spinner => {
              spinner.style.backgroundColor = color;
            });
          }
        });
        el.addEventListener('mouseout', () => {
          vnode.context.hoverTimeout = setTimeout(() => {
            binding.value(null);
            let spinners = document.querySelectorAll('.multiselect__spinner');
            spinners.forEach(spinner => {
              spinner.style.backgroundColor = '';
            });
          }, 100);
        });
      }
    }
  }
}
</script>

<style>
.status-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color-square {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px
}

#color-selector {
  width: 300px;
}

#color-selector .multiselect {
  width: fit-content;
}

#color-selector .multiselect__select {
  display: none;
}

#color-selector .multiselect__tags {
  width: 20px;
  height: 20px;
}

#color-selector .multiselect__content {
  display: block !important;
}

#color-selector .multiselect__content-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
  max-height: 250px;
  overflow-y: auto;
}

#color-selector .multiselect__element {
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 25%;
  border: 0;
}

.multiselect__option .color-square {
  border: 0;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.multiselect__option:hover .color-square {
  box-shadow: 0 0 0 2px #000;
  transform: scale(1.02);
}

#color-selector .multiselect--active .multiselect__tags {
  background-color: white !important;
  position: relative;
  width: 46px !important;
  margin-right: 0px !important;
}

#color-selector .multiselect--active .multiselect__tags input {
  display: none;
}

#color-selector .multiselect--active .multiselect__spinner {
  display: block !important;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#color-selector .multiselect--active .multiselect__spinner::before {
  display: none !important;
}

#color-selector .multiselect--active .multiselect__spinner::after {
  display: none !important;
}
</style>
