import {defineStore} from 'pinia';
import BlisserClient from '@/services/client';

export const useClientStore = defineStore('client', {
  state: () => ({
    client: null,
    userId: null,
    token: null,
  }),
  actions: {
    initializeClient(token) {
      this.client = new BlisserClient(token);
      sessionStorage.setItem('token', token);
    },
    setUserId(userId) {
      this.userId = userId;
      sessionStorage.setItem('userId', userId);
    },
    setToken(token) {
      this.token = token;
      sessionStorage.setItem('token', token);
    },
    rehydrateClient() {
      const token = sessionStorage.getItem('token');
      const userId = sessionStorage.getItem('userId');
      if (token) {
        this.client = new BlisserClient(token);
      }
      if (userId) {
        this.userId = userId;
      }
    },
  },
});
