<template>
  <sidebar-item :link="{name: organization.name, icon: 'nc-icon nc-app'}">
    <sidebar-item
      v-for="board in organization.boards"
      :key="board.id"
      :link="{name: board.name, path: `/admin/board/${board.id}`}">
    </sidebar-item>

    <sidebar-organization-item
      v-for="subOrg in organization.organizations"
      :key="subOrg.id"
      :organization="subOrg">
    </sidebar-organization-item>
  </sidebar-item>
</template>

<script>
export default {
  name: 'SidebarOrganizationItem',
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },
};
</script>
