<template>
  <PopUp>
    <template v-slot:content>
      <div class="title-container">
        <div class="title">My profile</div>
      </div>
      <div class="content-container">
        <ProfileAvatar v-if="userInfo" :avatar="userAvatar" @avatar-uploaded="handleAvatarUpload" />
        <EditProfileForm v-if="userInfo" :email="userEmail" :name="userName" @update="handleUpdate" />
      </div>
    </template>
  </PopUp>
</template>

<script>
import { useClientStore } from '@/stores/clientStore';
import SuccessNotification from "@/pages/Dashboard/Components/SuccessNotification.vue";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import ProfileAvatar from './UserProfile/ProfileAvatar';
import EditProfileForm from './UserProfile/EditProfileForm.vue';
import PopUp from '../Components/PopUp.vue';

export default {
  components: {
    ProfileAvatar,
    EditProfileForm,
    PopUp,
  },
  data() {
    return {
      userId: null,
      userInfo: null,
      userName: null,
      userEmail: null,
      userAvatar: null
    };
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    },
  },
  async mounted() {
    const clientStore = useClientStore();
    await clientStore.rehydrateClient();
    if (clientStore.userId && clientStore.client) {
      this.userId = await this.getUserId();
      this.userInfo = await this.getUserInfo();
      this.userEmail = this.userInfo.email;
      this.userAvatar = this.userInfo.avatar;
      this.userName = this.userInfo.name;
    } else {
      this.$router.push({ name: 'login' });
    };
  },
  methods: {
    getUserId() {
      const clientStore = useClientStore();
      return clientStore.userId;
    },
    handleAvatarUpload(newAvatar) {
      this.userAvatar = newAvatar;
    },
    handleUpdate(user) {
      this.userName = user.name;
      this.updateUser();
    },
    async getUserInfo() {
      try {
        const response = await this.client.GetUser(this.userId);
        return response.user
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async updateUser() {
      try {
        const updateData = {
          name: this.userName,
          avatar: this.userAvatar
        };
        await this.client.UpdateUser(this.userId, updateData);
        this.$notify({
          component: SuccessNotification,
          message: 'User updated successfully',
          icon: 'nc-icon nc-check-2',
          type: 'success'
        });
        this.$router.push({name: 'admin'});
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error updating user: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
  }
}

</script>

<style></style>
