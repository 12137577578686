<template>
  <div class="text-modal" @click="loadAndShowTask">
    {{ text }}
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { useClientStore } from '@/stores/clientStore';

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  methods: {
    async loadAndShowTask() {
      try {
        const clientStore = useClientStore();
        const response = await clientStore.client.FetchItem(clientStore.userId, this.taskId);
        const task = response.item;
        this.showTaskModal({
          name: task.name,
          details: task.details,
          done: task.done,
          due_date: new Date(task.dueDate * 1000),
          energy: task.energy,
          issue_number: task.issueNumber
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: `Error fetching task data: ${error.message}`,
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    },
    showTaskModal(task) {
      const { name, details, done, due_date, energy, issue_number } = task;
      Swal.fire({
        title: name,
        html: `
          <div style="text-align: left;">
            <strong>Issue Number:</strong> ${issue_number}<br>
            <strong>Name:</strong> ${name}<br>
            <strong>Details:</strong> ${details}<br>
            <strong>Status:</strong> ${done ? 'Done' : 'Not Done'}<br>
            <strong>Due Date:</strong> ${due_date.toLocaleDateString()}<br>
            <strong>Energy:</strong> ${energy}<br>
          </div>
        `,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-info btn-fill'
      });
    }
  }
}
</script>

<style>
.text-modal {
  cursor: pointer;
  transition: color 0.3s ease;
}

.text-modal:hover {
  color: #007bff;
}
</style>
