<template>
  <div class="update-task-form">
    <card>
      <h3 class="title">Update Task</h3>
      <div class="task-issue-number">
        <strong>Issue Number:</strong> {{ task.issue_number }}
      </div>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="updateTask">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Task Name">
              <el-input v-model="task.name" placeholder="Enter task name"></el-input>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <fg-input label="Details">
            <vue-editor ref="VueEditor" class="quill-additional-settings" v-model="task.details" placeholder="Enter task details"></vue-editor>
          </fg-input>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <fg-input label="Do you like doing this task?">
              <div class="elements-container">
                <div class="radio-buttons-container">
                  <input type="radio" id="energyNegative" value="-1" v-model.number="task.energy" name="energy"
                    class="hidden-radio">
                  <label for="energyNegative" :class="{ 'selected-image': task.energy === -1 }">
                    <span>Dislike</span>
                    <img src="@/assets/images/emojis-blisser-dislike.png" alt="-1" />
                  </label>
                  <input type="radio" id="energyNeutral" value="0" v-model.number="task.energy" name="energy"
                    class="hidden-radio">
                  <label for="energyNeutral" :class="{ 'selected-image': task.energy === 0 }">
                    <span>Same</span>
                    <img src="@/assets/images/emojis-blisser-same.png" alt="0" />
                  </label>
                  <input type="radio" id="energyPositive" value="1" v-model.number="task.energy" name="energy"
                    class="hidden-radio">
                  <label for="energyPositive" :class="{ 'selected-image': task.energy === 1 }">
                    <span>Love It!</span>
                    <img src="@/assets/images/emojis-blisser-love.png" alt="1" />
                  </label>
                </div>
                <div class="slider-container">
                  <el-slider v-model="task.energy" :min="-1" :max="1" :step="1" :show-tooltip="false"
                    @input="handleSliderChange"></el-slider>
                </div>
              </div>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }">
            <fg-input label="Assignees">
              <el-select v-model="task.board_members" multiple placeholder="Select assignees">
                <el-option v-for="member in selectedBoardMembers" :key="member.userId" :label="`${member.name}`"
                  :value="member.userId">
                </el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <fg-input label="Due Date">
            <el-date-picker v-model="task.due_date" type="date" placeholder="Select due date"></el-date-picker>
          </fg-input>

          <ValidationProvider v-slot="{ errors }">
            <fg-input label="Project Tags">
              <el-select v-model="task.project_tags" multiple placeholder="Select tags">
                <el-option v-for="tag in availableTags" :key="tag" :label="tag" :value="tag"></el-option>
              </el-select>
              <span class="error-message">{{ errors[0] }}</span>
            </fg-input>
          </ValidationProvider>

          <button type="submit" class="btn btn-fill btn-info" :disabled="invalid">Update Task</button>
          <button type="button" class="btn btn-fill btn-danger ml-3" @click="cancelUpdate">Cancel</button>
        </form>
      </ValidationObserver>
    </card>
  </div>
</template>

<script>
import { Input, DatePicker, Select, Option, Button, Slider } from 'element-ui';
import { FormGroupInput as FgInput } from 'src/components/index';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useClientStore } from "@/stores/clientStore";
import SuccessNotification from "@/pages/Dashboard/Components/SuccessNotification.vue";
import FailureNotification from "@/pages/Dashboard/Components/FailureNotification.vue";
import { VueEditor } from 'vue2-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  components: {
    FgInput,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ValidationProvider,
    ValidationObserver,
    ElSlider: Slider,
    VueEditor: VueEditor,
  },
  data() {
    return {
      task: {
        name: '',
        details: '',
        board_id: null,
        energy: 1,
        due_date: null,
        project_tags: [],
        issue_number: 0,
        board_members: [],
        assignees: [],
      },
      board: '',
      availableTags: ['testing_API', 'urgent', 'high_priority', 'low_priority']
    };
  },
  watch: {
    'task.board_members': function (newVal) {
      this.task.assignees = newVal;
    }
  },
  computed: {
    client() {
      const clientStore = useClientStore();
      return clientStore.client;
    },
    selectedBoardMembers() {
      const selectedBoard = this.board;
      return selectedBoard ? selectedBoard.members : [];
    }
  },
  methods: {
    async loadTaskData(taskId) {
      try {
        const response = await this.client.FetchItem(this.getUserId(), taskId);
        this.task.name = response.item.name;
        this.task.details = response.item.details;
        this.task.board_id = response.item.boardId;
        this.task.energy = response.item.energy;
        this.task.project_tags = response.item.projectTags;
        this.task.due_date = response.item.dueDate === 0 ? null : new Date(response.item.dueDate * 1000);
        this.task.issue_number = response.item.issueNumber;
        this.task.assignees = response.item.assignees;
        this.task.board_members = this.task.assignees.slice();
        this.board = await this.fetchBoard(this.task.board_id);
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error fetching task data: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    async fetchBoard(boardId) {
      try {
        const response = await this.client.GetBoardById(this.getUserId(), boardId);
        return response.board
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error fetching boards: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    cancelUpdate() {
      this.$router.go(-1);
    },
    async updateTask() {
      try {
        if (this.task.due_date) {
          this.task.due_date = this.task.due_date.getTime() / 1000;
        }
        await this.client.UpdateItem(this.getUserId(), this.$route.params.id, this.task);
        this.$router.push({ name: 'Board View', params: { id: this.task.board_id } });
        this.$notify({
          component: SuccessNotification,
          message: 'Task updated successfully',
          icon: 'nc-icon nc-check-2',
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          component: FailureNotification,
          message: `Error: ${error.message}`,
          icon: 'nc-icon nc-alert-circle-i',
          type: 'danger'
        });
      }
    },
    getUserId() {
      const clientStore = useClientStore();
      return clientStore.userId;
    },
    handleSliderChange(value) {

    },
  },
  async mounted() {
    try {
      const clientStore = useClientStore();
      await clientStore.rehydrateClient();

      if (!clientStore.userId || !clientStore.client) {
        throw new Error('Client or UserId is not available after rehydration');
      }

      const taskId = this.$route.params.id;
      if (!taskId) {
        throw new Error('Task ID not found in route parameters');
      }

      await this.loadTaskData(taskId);
    } catch (error) {
      this.$notify({
        component: FailureNotification,
        message: `Error: ${error.message}`,
        icon: 'nc-icon nc-alert-circle-i',
        type: 'danger'
      });
      this.$router.push({ name: 'login' });
    }
    this.$nextTick(() => {
      const editorContainer = this.$refs.VueEditor.$el.querySelector('.ql-container');
      if (editorContainer) {
        editorContainer.style.height = '100px';
      }
    });
  },
}

</script>

<style>
.error-message {
  color: red;
  font-size: 0.875em;
}
</style>
