<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <LAlert v-if="showError" type="danger" dismissible>
          {{ errorMessage }}
        </LAlert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Register</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input type="email"
                              :error="failed ? 'The Email field is required': null"
                              :hasSuccess="passed"
                              label="Email address"
                              name="email"
                              v-model="email">
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input type="password"
                              :error="failed ? 'The Password field is required': null"
                              :hasSuccess="passed"
                              name="password"
                              label="Password"
                              v-model="password">
                    </fg-input>
                  </ValidationProvider>

                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-fill btn-info btn-round btn-wd ">Create</button>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {FadeRenderTransition} from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import {extend} from "vee-validate";
import {email, min, required} from "vee-validate/dist/rules";
import {auth, createUserWithEmailAndPassword} from '@/firebase.js';
import LAlert from "@/components/Alert.vue";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LAlert,
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      recaptchaVerifier: null,
      showError: false,
      errorMessage: ''
    };
  },
  mounted() {
    this.recaptchaVerifier = new RecaptchaVerifier(this.$refs.recaptcha, {
      'size': 'invisible',
      'callback': (response) => {
      }
    }, auth);
    this.recaptchaVerifier.render();
  },
  methods: {
    async submit() {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        if (user) {
          this.$router.push({name: 'admin'});
        }
      } catch (createError) {
        this.errorMessage = createError.message || 'An error occurred during registration.';
        this.showError = true;
      }
    },
    notifyVue(verticalAlign, horizontalAlign, type, message) {
      this.$notify({
        icon: 'nc-icon nc-app',
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: type,
        message: message
      });
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
  },
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
