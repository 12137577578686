<template>
  <span><b>Error:</b> Something went wrong! </span>
</template>

<script>
export default {
  name: 'FailureNotification',

}
</script>

<style>
</style>
